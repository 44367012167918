import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Stack`}</h1>
    <p>{`Stack is a responsive layout object
that allows you to stack content
blocks in a single vertical column
and split them into multiple columns
at wider viewports.`}</p>
    <ComponentPreview componentName="stack--default-story" hasReact hasAngular hasHTML titleAttr="Stack Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`It is often desirable for blocks of content
to be laid out in a single column on narrow
viewports, but multiple side-by-side
columns on wider viewports.
The Stack component can be used
to control these kinds of layout
changes between viewport sizes.`}</p>
    <p>{`Stack can be used for both
page-level and component-level
layout. For example, you can use
Stack to create a two-column page
layout or within a component to
make two buttons stack at narrow
viewports and split next to each
other on wider viewports.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Stack objects can be nested inside other Stack objects.`}</li>
      <li parentName="ul">{`Stack Items can split into side-by-side columns at the following breakpoints:`}<ul parentName="li">
          <li parentName="ul">{`XX Small (xxs) – `}<inlineCode parentName="li">{`320px`}</inlineCode></li>
          <li parentName="ul">{`Extra Small (xs) – `}<inlineCode parentName="li">{`480px`}</inlineCode></li>
          <li parentName="ul">{`Small (s) - `}<inlineCode parentName="li">{`680px`}</inlineCode></li>
          <li parentName="ul">{`Medium (m) – `}<inlineCode parentName="li">{`880px`}</inlineCode></li>
          <li parentName="ul">{`Large (l) – `}<inlineCode parentName="li">{`1080px`}</inlineCode></li>
          <li parentName="ul">{`Extra Large (xl) – `}<inlineCode parentName="li">{`1280px`}</inlineCode></li>
        </ul></li>
      <li parentName="ul">{`Stack can reverse the direction of the
Spark Item elements. This only works when
using the split-reverse modifier and can’t
be used with a single column Stack.`}</li>
      <li parentName="ul">{`Spacing between Stack Items can be set using
the available `}<a parentName="li" {...{
          "href": "/using-spark/foundations/space"
        }}>{`Spacing`}</a>{` sizes.`}<ul parentName="li">
          <li parentName="ul">{`Tiny - `}<inlineCode parentName="li">{`4px`}</inlineCode></li>
          <li parentName="ul">{`Small - `}<inlineCode parentName="li">{`8px`}</inlineCode></li>
          <li parentName="ul">{`Medium - `}<inlineCode parentName="li">{`16px`}</inlineCode></li>
          <li parentName="ul">{`Large - `}<inlineCode parentName="li">{`32px`}</inlineCode></li>
          <li parentName="ul">{`Huge - `}<inlineCode parentName="li">{`64px`}</inlineCode></li>
        </ul></li>
      <li parentName="ul">{`All Stack Items inside a Stack must add
up to 100% width or the layout will not work.
For example, if you have a Stack Item
set to 1/2 width you can have one more
Stack Item at 1/2 width, two more
at 1/4 width each, etc.`}</li>
      <li parentName="ul">{`Stack Items don’t have any internal
padding. To add padding, consider using the `}<a parentName="li" {...{
          "href": "/using-spark/components/box"
        }}>{`Box Object.`}</a></li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Default`}</h3>
    <p>{`By default, Stack Items will form a single vertical column.`}</p>
    <ComponentPreview componentName="stack--default-story" hasReact hasAngular hasHTML titleAttr="Default Stack Example" mdxType="ComponentPreview" />
    <h3>{`Stack with Space Between Items`}</h3>
    <p>{`Space can be added between Stack Items.
By default the spacing is below each item.
When the items are split, the spacing is
on the right. The available sizes are
tiny, small, medium, large, and huge.
The example shows medium spacing and
splits at the small breakpoint (“s”).`}</p>
    <ComponentPreview componentName="stack--stack-split" hasReact hasAngular hasHTML titleAttr="Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with Two Columns`}</h3>
    <p>{`This layout configuration splits
into two columns at a set
breakpoint. The example
shows the split at extra
small breakpoint (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-two-col" hasReact hasAngular hasHTML titleAttr="Two Column Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with Three Columns`}</h3>
    <p>{`This layout configuration splits into
three columns at a set breakpoint.
The example shows the split at
extra small breakpoint (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-three-col" hasReact hasAngular hasHTML titleAttr="Three Column Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with Four Columns`}</h3>
    <p>{`This layout configuration splits
into four columns at a set breakpoint.
The example shows the split at extra
small breakpoint (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-four-col" hasReact hasAngular hasHTML titleAttr="Four Column Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with Five Columns`}</h3>
    <p>{`This layout configuration splits
into five columns at a set breakpoint.
The example shows the split at
extra small breakpoint (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-five-col" hasReact hasAngular hasHTML titleAttr="Five Column Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with Six Columns`}</h3>
    <p>{`This layout configuration splits into
six columns at a set breakpoint. The
example shows the split at extra
small breakpoint (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-six-col" hasReact hasAngular hasHTML titleAttr="Six Column Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with 75/25 Columns`}</h3>
    <p>{`This layout configuration splits into
two columns at a set breakpoint. One
column takes up 75% and the other
takes up 25% of the available width.
The example shows the split at extra small (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-layout-three-fourths" hasReact hasAngular hasHTML titleAttr="75/25 Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with 60/40 Columns`}</h3>
    <p>{`This layout configuration splits into
two columns at a set breakpoint.
One column takes up 60% and the
other takes up 40% of the
available width. The example
shows the split at extra small (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-layout-three-fifths" hasReact hasAngular hasHTML titleAttr="60/40 Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with 70/30 Columns`}</h3>
    <p>{`This layout configuration splits
into two columns at a set breakpoint.
One column takes up 70% and the
other takes up 30% of the available
width. The example shows the split
at extra small (“xs”).`}</p>
    <ComponentPreview componentName="stack--stack-split-layout-three-tenths" hasReact hasAngular hasHTML titleAttr="70/30 Split Stack Example" mdxType="ComponentPreview" />
    <h3>{`Split Stack with Mixed and Nested Columns`}</h3>
    <p>{`This layout configuration shows many
combinations in which Stack can be
used. It’s flexibility shows that
Stack objects can be nested inside
of each other and even contain
flexbox elements meant to fill
in the remaining space.`}</p>
    <ComponentPreview componentName="stack--stack-split-layout-mixed" hasReact hasAngular hasHTML titleAttr="Split Stack Example with Mixed and Nested Columns" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Stack must have a container to wrap Stack Items.
This container dictates spacing between items and split breakpoints.`}</li>
      <li parentName="ul">{`Stack Items must be inside the Stack container.
All Stack Item sizes must add up to 100% in total width.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Reversing the direction Stack splits
could affect the order a screen reader
will read the content. This can vary
depending on device and browser
context. Please use with caution.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      